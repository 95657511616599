import useFetch from "./useFetch";
import TenantList from "./TenantList";

const TestFetch = () => {
  const { error, isPending, data: tenants } = useFetch('/api/tenants')

  return (
    <div className="home">
      { error && <div>{ error }</div> }
      { isPending && <div>Loading...</div> }
      { tenants && <TenantList tenants={tenants._embedded.tenants} /> }
    </div>
  );
}
 
export default TestFetch;