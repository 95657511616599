import { Link } from 'react-router-dom';
import React from 'react';
import styles from './styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import { withStyles } from 'tss-react/mui';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";




const LoginComponent = (props) => {

    const [searchParams, setSearchParams] = useSearchParams();


    const {classes} = props;

    const [state, setState] = useState({
        email: null,
        password: null,
        serverError: false
      });

    const [csrf, setCsrf] = useState();
    const [error, setError] = useState();



    useEffect(() => {
      // console.log("Hello!");
      
      const abortCont = new AbortController();

      fetch("/csrf", { signal: abortCont.signal })
      .then(res => {
        if (!res.ok) { // error coming back from server
          throw Error('could not fetch the data for that resource');
        } 
        console.log("Received, getting data...");
        return res.json();
      })
      .then(data => {
        console.log(data);
        setCsrf(data.token);
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          console.log('fetch aborted')
        } else {
          // auto catches network / connection error
          setError(err.message);
        }
      });

      // console.log('SEAR');
      console.log(searchParams.entries());
      for (const entry of searchParams.entries()) {
        const [param, value] = entry;
        if (param === "error") {
          state.serverError="Error logging in!";
          setState(state);
        }
        console.log(entry);
      }
  

      }, [])

      const userTyping = (whichInput, event) => {
        switch (whichInput) {
          case 'email':
            state.email = event.target.value;
            setState(state);
            break;
    
          case 'password':
            state.password=event.target.value;
            setState(state);
            break;
    
          default:
            break;
        }
      }
    
      const submitLogin = async (e) => {
        //e.preventDefault(); // This is to prevent the automatic refreshing of the page on submit.
    
        // await firebase
        //   .auth()
        //   .signInWithEmailAndPassword(this.state.email, this.state.password)
        //   .then(() => {
        //     this.props.history.push('/dashboard');
        //   }, err => {
        //     this.setState({ serverError: true });
        //     console.log('Error logging in: ', err);
        //   });
      };
    

  
      return (
      <main className={classes.main}>
        <CssBaseline/>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Log In!
          </Typography>
          <form onSubmit={(e) => submitLogin(e)} className={classes.form} action="/login" method="POST">
            <FormControl required fullWidth margin='normal'>
              <InputLabel htmlFor='login-email-input'>Enter Your Email</InputLabel>
              <Input autoComplete='email' autoFocus onChange={(e) => userTyping('email', e)} id='login-email-input' name="username"></Input>
            </FormControl>
            <FormControl required fullWidth margin='normal'>
              <InputLabel htmlFor='login-password-input'>Enter Your Password</InputLabel>
              <Input autoComplete="current-password" type="password" onChange={(e) => userTyping('password', e)} id='login-password-input' name="password"></Input>
            </FormControl>
            <input type="hidden" name="_csrf" value={ csrf }/>
            <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>Log In</Button>
          </form>
          { state.serverError ? 
            <Typography className={classes.errorText} component='h5' variant='h6'>
              Incorrect Login Information
            </Typography> :
            null
          }
          <h5 className={classes.noAccountHeader}>Don't Have An Account?</h5>
          <Link className={classes.signUpLink} to='/signup'>Sign Up!</Link>
        </Paper>
      </main>
    );
  
  }
  

  export default withStyles(LoginComponent,styles);