import { Link } from 'react-router-dom';
import React from 'react';
import styles from './styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import { withStyles } from 'tss-react/mui';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';




const SignupComponent = (props) => {

  const {classes} = props;

  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  const [state, setState] = useState( {
    email: null,
    password: null,
    passwordConfirmation: null,
    signupError: ''
  });

  const navigate = useNavigate();

  const formIsValid = () => state.password === state.passwordConfirmation;

  const userTyping = (whichInput, event) => {
    switch (whichInput) {
      case 'email':
        state.email = event.target.value;
        setState(state);
        break;

      case 'password':
        state.password = event.target.value;
        setState(state);
        break;

      case 'passwordConfirmation':
        state.passwordConfirmation = event.target.value;
        setState(state);
        break;

      default:
        break;
    }
  }

  const submitSignup = (e) => {

    e.preventDefault(); // This is to prevent the automatic refreshing of the page on submit.
   
    
    if(!formIsValid()) {
      state.signupError = 'Passwords do not match';
      setState(state);
      // auto catches network / connection error
      setIsPending(false);
      setError(state.signupError);
      return;
    }


    const user = { username: state.email,
                    password: state.password };

  

    console.log("Getting CSRF Token...");

    const url = "/signup"
    const postObject = user;

    const cAbortCont = new AbortController();
  
    fetch("/csrf", { signal: cAbortCont.signal })
        .then(res => {
          if (!res.ok) { // error coming back from server
            throw Error('could not fetch CSRF Token');
          } 
          console.log("Received CSRF Token...");
          return res.json();
        })
        .then(data => {
          console.log(data);
          const abortCont = new AbortController();
        
          
          fetch(url, { signal: abortCont.signal,
            method: 'POST',
            headers: { "Content-Type": "application/json" , "X-CSRF-TOKEN": data.token},
            body: JSON.stringify(postObject)  
          })
          .then(res => {
            if (!res.ok) { // error coming back from server
              throw Error('could not fetch the data for that resource');
            } 
            console.log(res);
            return res.json();
          })
          .then(data => {
            console.log(data);
            navigate("/login");
            setIsPending(false);
            setData(data);
            setError(null);
          })
          .catch(err => {
            if (err.name === 'AbortError') {
              console.log('fetch aborted')
            } else {
              state.signupError = 'Failed to create user';
              setState(state);
              // auto catches network / connection error
              setIsPending(false);
              setError(err.message);
            }
          })
        })
        .catch(err => {
          if (err.name === 'AbortError') {
            console.log('fetch aborted')
            } else {
              // auto catches network / connection error
              setError(err.message);
            }
          });

    // fetch('/api/users', {
    //   method: 'POST',
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(user)
    // }).then(() => {
    //   navigate("/");
      
    // })

  };



    return (
      <main className={classes.main}>
        <CssBaseline/>

        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Sign Up!
          </Typography>
          <form onSubmit={(e) => submitSignup(e)} className={classes.form}>
            <FormControl required fullWidth margin='normal'>
              <InputLabel htmlFor='signup-email-input'>Enter Your Email</InputLabel>
              <Input autoComplete='email' autoFocus onChange={(e) => userTyping('email', e)} id='signup-email-input'></Input>
            </FormControl>
            <FormControl required fullWidth margin='normal'>
              <InputLabel htmlFor='signup-password-input'>Create A Password</InputLabel>
              <Input type="password" onChange={(e) => userTyping('password', e)} id='signup-password-input'></Input>
            </FormControl>
            <FormControl required fullWidth margin='normal'>
              <InputLabel htmlFor='signup-password-confirmation-input'>Confirm Your Password</InputLabel>
              <Input type="password" onChange={(e) => userTyping('passwordConfirmation', e)} id='signup-password-confirmation-input'></Input>
            </FormControl>
            <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>Submit</Button>
          </form>
          { 
            state.signupError ? 
            <Typography className={classes.errorText} component='h5' variant='h6'>
              {state.signupError}
            </Typography> :
            null
          }
          <h5 className={classes.hasAccountHeader}>Already Have An Account?</h5>
          <Link className={classes.logInLink} to='/login'>Log In!</Link>
        </Paper>

        
      </main>

  )

}

  






export default withStyles(SignupComponent,styles);