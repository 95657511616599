import React from 'react';
import styles from './styles';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';


const LogoutComponent = (props) => {

    const {classes} = props;

    const [state, setState] = useState({
        email: null,
        password: null,
        serverError: false
      });

    const [csrf, setCsrf] = useState();
    const [error, setError] = useState();

    useEffect(() => {
      console.log("Hello!");
      
      const abortCont = new AbortController();

      fetch("/csrf", { signal: abortCont.signal })
      .then(res => {
        if (!res.ok) { // error coming back from server
          throw Error('could not fetch the data for that resource');
        } 
        console.log("Received, getting data...");
        return res.json();
      })
      .then(data => {
        console.log(data);
        setCsrf(data.token);
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          console.log('fetch aborted')
        } else {
          // auto catches network / connection error
          setError(err.message);
        }
      });

      }, [])

  
      return (
        <form className={classes.form} action="/logout" method="POST">
        <input type="hidden" name="_csrf" value={ csrf }/>
        <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>Log Out</Button>
      </form>
    );



  
  }
  

  export default withStyles(LogoutComponent,styles);