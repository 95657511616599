import $ from 'jquery';

const ChatComponent = () => {

    function receiveMessage(msg) {

   
        const windows = document.getElementsByClassName("msg-page");
        const chatWindow = windows[0];
    
        var message = document.createElement("div");
        message.setAttribute("class","received-chats");

        var textContainer = document.createElement("div");
        textContainer.setAttribute("class","received-msg")
        message.appendChild(textContainer);

        var text = document.createElement("div");
        text.setAttribute("class","received-msg-inbox");
        textContainer.appendChild(text);

        var line = document.createElement("p");
        line.setAttribute("class","single-msg");
        line.innerHTML=msg;
        text.appendChild(line);

        var timestamp = document.createElement("span");
        timestamp.setAttribute("class","time");
        timestamp.innerHTML = new Date().toLocaleString();
        
        text.appendChild(timestamp);

        chatWindow.appendChild(message);

        chatWindow.scrollTo(0,chatWindow.scrollHeight);
        

    }

    function sendMessage() {
        const inputs = document.getElementsByClassName("form-control");
        const textInput = inputs[0];
        console.log("Tweet");
        console.log(textInput.value);
   
        const windows = document.getElementsByClassName("msg-page");
        const chatWindow = windows[0];
    
        var message = document.createElement("div");
        message.setAttribute("class","outgoing-chats");

        var textContainer = document.createElement("div");
        textContainer.setAttribute("class","outgoing-msg")
        message.appendChild(textContainer);

        var text = document.createElement("div");
        text.setAttribute("class","outgoing-chats-msg");
        textContainer.appendChild(text);

        var line = document.createElement("p");
        line.setAttribute("class","multi-msg");
        line.innerHTML=textInput.value;
        text.appendChild(line);

        var timestamp = document.createElement("span");
        timestamp.setAttribute("class","time");
        timestamp.innerHTML = new Date().toLocaleString();
        
        text.appendChild(timestamp);

        chatWindow.appendChild(message);

        var prompt = textInput.value;

        $.ajax({
            // TODO: Is this secure?
            url: "/chatgpt?query=" + encodeURIComponent(prompt),
            data: "", //ur data to be sent to server
            contentType: "application/json; charset=utf-8", 
            type: "GET",
            success: function (data) {
                console.log("Success");
                receiveMessage(data);
            },
            error: function (x, y, z) {
                console.log("Error!!");
               alert(x.responseText +"  " +x.status);
            }
        });


        textInput.value="";

        chatWindow.scrollTo(0,chatWindow.scrollHeight);
   
    
    }

    function keyTyped(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            sendMessage();
        }   
    }

    window.onload = function()
    { 
        const buttons = document.getElementsByClassName("bi");
        const sendButton = buttons[0];
        sendButton.addEventListener("click",sendMessage);

        const inputs = document.getElementsByClassName("form-control");
        const textInput = inputs[0];
        textInput.addEventListener("keyup",keyTyped)

     }
    

    return (
        <div height="4000px">
            {/* <head> */}
                <link rel="stylesheet" href="/chat/styles.css"/>


                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css"/>


            {/* </head> */}

            <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js"></script>

            <div className="container">

                <div className="msg-header">
                    <div className="container1">

                        <img src="/chat/user1.png" alt="user1" className="msgimg"/>
                        <div className="active ">
                            <p>UnbusyGPT</p>
                        </div>

                    </div>


                </div>
            {/* <!-- msg-header section ends --> */}

            {/* <!-- Chat inbox  --> */}
            <div className="chat-page">
                <div className="msg-inbox">
                    <div className="chats">

                        {/* <!-- Message container --> */}
                        <div className="msg-page">

                            {/* <!-- Incoming messages --> */}

                            <div className="received-chats">
                                <div className="received-chats-img">
                                    <img src="/chat/user2.png" alt="user2"/>

                                </div>
                            <div className="received-msg">
                                <div className="received-msg-inbox">
                                    <p>Hello! What can I do for you today?</p>
                                    <span className="time">18:06 PM | July 24 </span>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>

                {/* //                 <!-- msg-bottom section --> */}


                 <div className="msg-bottom">

                     <div className="input-group">
                        {/* <!-- <input type="text" class="form-control" placeholder="Write message..."> --> */}
                        <input type="text" className="form-control" placeholder="" onKeyUp={keyTyped}/>
                        <span className="input-group-text send-icon "><i className="bi bi-send " onClick={sendMessage}></i>
                        </span>

                    </div>
                </div>
            </div>
            </div>

        </div>
    </div>
    )
    






}

export default ChatComponent