import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import SignupComponent from './Signup/signup';
import LoginComponent from './Login/login';
import LogoutComponent from './Login/logout';
import TestFetch from './testfetch';
import ChatComponent from './Chat/chat';


const router = createBrowserRouter([
  {
    path: "/",
    element: <div>
      <ChatComponent/>
      <hr/>
      <a href="/oauth/initiate?type=quickbooks">Reauthenticate to quickbooks</a>
      <hr/>
      <LogoutComponent/>
      
    </div>,
  },
  {
    path: "/signup",
    element: <SignupComponent/>,
  },
  {
    path: "/login",
    element: <LoginComponent/>,
  },
  { path: "/testfetch",
    element: <TestFetch/>,
  }
  
],{
  basename: "/app",
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
