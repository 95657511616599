
const TenantList = ({ tenants }) => {
  return (
    <div className="blog-list">
      {tenants.map(tenant => (
        <div className="blog-preview" key={tenant.name} >
          {/* <Link to={`/blogs/${blog.id}`}> */}
            <h2>{ tenant.name }</h2>
            <h2>{ tenant.subdomain }</h2>
          {/* </Link> */}
        </div>
      ))}
    </div>
  );
}
 
export default TenantList;